import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { Button } from "@ryerson/frontend.button";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { VideoWrapperProps } from "@components/Helper/common";
import { useApplication } from "@ryerson/frontend.application";
import { TsTableContentProps } from "./TsTableContent";
import { navigate } from "gatsby";

export interface HeroProps {
	heroTitle: string;
	heroDescription: string;
	heroVideoId?: string;
	mobileHeaderText: string;
	heroTable?: HeroTable[];
	heroType?: "simpleLeft" | "simpleRight" | "3TsTable";
	tTable?: TsTableContent;
	thumbnailUrl?: string;
}

export interface HeroTable {
	col1: string;
	col2: HeroTableColumnTwo[];
	buttonLabel: string;
	linkAddress: string;
	unlinedRow?: boolean;
}

export interface HeroTableColumnTwo {
	bold: string;
	normal: string;
}

export interface TsTableContent {
	title: string;
	headerRow: TsTableRow;
	rows: TsTableRow[];
	disclaimer: string;
}

export interface TsTableRow {
	col1: string;
	col2: string;
	col3: string;
	col4: string;
}

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { theme } = props;
		/*
        return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 580px;
				height: 360px;
				margin-top: 37px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 300px;
				margin-bottom: 30px;
			}
		`;
        */
		return css`
			width: 100%;
			height: 360px;
			@media (max-width: ${theme.breakpoints.xxl}) {
				max-width: calc(100% - 60px);
				height: 320px;
			}
			@media only screen and (max-width: ${theme.breakpoints.sm}) {
				max-width: 300px;
				height: 270px;
			}
		`;
	}}
`;

const VideoWrapperSmall = styled.div`
	max-width: 443px;
	height: 350px;
	margin-bottom: 30px;
`;

const ImageWrapper = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 580px;
				height: 360px;
				margin-top: 37px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 300px;
				margin-bottom: 30px;
			}
		`;
	}}
`;

const ImageWrapperSmall = styled.div`
	max-width: 443px;
	height: 350px;
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 10px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.header};
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 32px;
				margin-top: 20px;
			}
		`;
	}}
`;

const HeroText = styled.div`
	max-width: 400px;
	margin-bottom: 30px;
`;

const BottomSpacer = styled.div`
	height: 110px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 40px;
			}
		`;
	}}
`;

const MobileContainer = styled.div`
	margin-bottom: -20px;
`;

const MobileDivider = styled.div`
	width: 100%;
	margin: 0;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.darkGray};
			opacity: 0.5;
		`;
	}}
`;

const Hero: React.FC<HeroProps> = ({
	heroTitle,
	heroDescription,
	heroVideoId,
	mobileHeaderText,
	heroTable,
	heroType,
	tTable,
	thumbnailUrl,
}) => {
	const { theme } = useTheme();

	const tableStyles = css`
		background-color: ${theme.colors.primary.background};
		margin-top: 50px;
	`;

	const tableRowStyles = css`
		padding: 10px 20px 10px 20px;
		:first-of-type {
			padding: 16px 20px 7px 20px;
			background-color: ${theme.colors.primary.lighterGray};
			font-weight: 500;
		}
		:nth-of-type(2) {
			margin-top: 10px;
		}
	`;

	const tableColumnOne = css`
		min-width: 15%;
		max-width: 15%;
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;
	`;
	const tableColumnTwo = css`
		width: 70%;
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;
	`;
	const tableColumnThree = css`
		max-width: 15%;
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;
		padding-left: 15px;
	`;

	const mobileProcessTitle = css`
		display: inline-block;
		box-sizing: border-box;
		width: 50%;
	`;

	const mobileProcessButtonContainer = css`
		display: inline-block;
		box-sizing: border-box;
		width: 50%;
	`;

	const mobileDescriptionText = css`
		padding: 15px 0px;
	`;

	const mobileRow = css`
		margin-bottom: 10px;
		:first-of-type {
			margin-top: 32px;
		}
		:last-of-type {
			margin-bottom: 60px;
		}
	`;

	const columnTwoTextStyles = css`
		margin-bottom: 10px;
	`;

	const tTableRowStyles = css`
		padding: 18px 20px 15px 20px;
		background-color: ${theme.colors.tertiary.header};
		:first-of-type {
			padding: 19px 20px 16px 20px;
			background-color: ${theme.colors.primary.primaryBrand};
			font-weight: 500;
		}
		:last-of-type {
			padding: 18px 20px 28px 20px;
		}
		position: relative;
		:after {
			content: "";
			background: ${theme.colors.primary.header};
			position: absolute;
			bottom: 0;
			left: 2.5%;
			height: 1px;
			width: 95%;
			opacity: 0.1;
		}
	`;

	const tTableCol1 = css`
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;
		width: 25%;
	`;
	const tTableCol2 = css`
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;
		width: 25%;
		text-align: left;
	`;
	const tTableCol3 = css`
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;
		width: 30%;
		text-align: left;
	`;
	const tTableCol4 = css`
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;
		width: 20%;
		text-align: left;
	`;
	const tTableSymbols = css`
		display: inline-block;
		font-size: 28px;
		vertical-align: middle;
		float: right;
		padding-right: 30px;
	`;

	const imageStyles = css`
		width: 100%;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			height: 100%;
			object-fit: cover;
		}
	`;

	const heroLayout = (
		heroTitle: string,
		heroDescription: string,
		heroVideoId?: string,
		heroTable?: HeroTable[],
		heroType?: string,
		tTable?: TsTableContent,
		thumbnailUrl?: string
	) => {
		const {
			localization: { language },
		} = useApplication();

		if (!tTable) {
			tTable = TsTableContentProps[language];
		}

		if (heroType === "simpleLeft") {
			return (
				<>
					<Grid>
						<Row>
							<Column lg={6}>
								<Typography
									variant="h1"
									type="tertiary"
									css={{ marginBottom: "73px", marginTop: "35px" }}
								>
									{heroTitle}
								</Typography>
								{heroVideoId ? (
									<VideoWrapper theme={theme}>
										<Video
											videoId={heroVideoId}
											buttonOrientation="right"
											imageUrl={thumbnailUrl}
										></Video>
									</VideoWrapper>
								) : (
									<ImageWrapper theme={theme}>
										<img src={thumbnailUrl} css={imageStyles} />
									</ImageWrapper>
								)}
							</Column>
							<Column lg={6}>
								<HeroText>
									<Typography
										variant="p"
										color={theme.colors.primary.lighterGray}
										size="lg"
										css={{ marginTop: "163px", marginLeft: "106px" }}
									>
										{heroDescription}
									</Typography>
								</HeroText>
							</Column>
						</Row>
					</Grid>
					{heroTable ? (
						<div css={tableStyles}>
							{heroTable.map((row, index) => {
								return (
									<div css={tableRowStyles} key={index}>
										<div css={tableColumnOne}>
											<Typography
												variant="div"
												weight="bold"
												size="sm"
												color={theme.colors.primary.secondaryBrand}
											>
												{row.col1}
											</Typography>
										</div>
										<div css={tableColumnTwo}>
											{row.col2.map((line, index) => {
												return (
													<Typography
														variant="div"
														size="sm"
														color={theme.colors.primary.secondaryBrand}
														key={index}
														css={columnTwoTextStyles}
													>
														<Typography
															font="inherit"
															weight="bold"
															size="sm"
															color={
																theme.colors.primary.secondaryBrand
															}
															key={`${index}${Math.random()}`}
														>
															{line.bold}
														</Typography>{" "}
														{line.normal}
													</Typography>
												);
											})}
										</div>
										<div css={tableColumnThree}>
											{row.buttonLabel.length > 0 && (
												<Button
													type="secondary"
													size="xs"
													paddingType="relaxed"
													fullwidth={true}
													align="center"
													label={row.buttonLabel}
													onClick={() => {
														navigate(row.linkAddress);
													}}
												/>
											)}
										</div>
										{row.unlinedRow !== true && <Divider theme={theme} />}
									</div>
								);
							})}
						</div>
					) : (
						<></>
					)}
					<BottomSpacer theme={theme} />
				</>
			);
		} else if (heroType === "3TsTable") {
			return (
				<>
					<Grid>
						<Row>
							<Column lg={5}>
								<Typography
									variant="h1"
									type="tertiary"
									css={{ marginBottom: "35px", marginTop: "35px" }}
								>
									{heroTitle}
								</Typography>
								<Typography
									variant="p"
									color={theme.colors.primary.lighterGray}
									size="lg"
									css={{ width: "75%", minHeight: "175px", marginBottom: "35px" }}
								>
									{heroDescription}
								</Typography>
								{heroVideoId ? (
									<VideoWrapperSmall>
										<Video
											videoId={heroVideoId}
											buttonOrientation="top-right"
											imageUrl={thumbnailUrl}
										></Video>
									</VideoWrapperSmall>
								) : (
									<ImageWrapperSmall theme={theme}>
										<img src={thumbnailUrl} css={imageStyles} />
									</ImageWrapperSmall>
								)}
							</Column>
							<Column lg={7}>
								<Typography
									variant="h2"
									type="tertiary"
									css={{ marginBottom: "73px", marginTop: "192px" }}
								>
									{tTable.title}
								</Typography>
								<div css={tTableRowStyles}>
									<div css={tTableCol1}>
										<Typography
											variant="div"
											weight="bold"
											size="md"
											color={theme.colors.primary.background}
											css={{ display: "inline-block" }}
										>
											{tTable.headerRow.col1}
										</Typography>
										<Typography
											variant="div"
											weight="bold"
											size="sm"
											color={theme.colors.primary.background}
											css={tTableSymbols}
										>
											{"+"}
										</Typography>
									</div>
									<div css={tTableCol2}>
										<Typography
											variant="div"
											weight="bold"
											size="md"
											color={theme.colors.primary.background}
											css={{ display: "inline-block" }}
										>
											{tTable.headerRow.col2}
										</Typography>
										<Typography
											variant="div"
											weight="bold"
											size="sm"
											color={theme.colors.primary.background}
											css={tTableSymbols}
										>
											{"+"}
										</Typography>
									</div>
									<div css={tTableCol3}>
										<Typography
											variant="div"
											weight="bold"
											size="md"
											color={theme.colors.primary.background}
											css={{ display: "inline-block" }}
										>
											{tTable.headerRow.col3}
										</Typography>
										<Typography
											variant="div"
											weight="bold"
											size="sm"
											color={theme.colors.primary.background}
											css={tTableSymbols}
										>
											{"="}
										</Typography>
									</div>
									<div css={tTableCol4}>
										<Typography
											variant="div"
											weight="bold"
											size="md"
											color={theme.colors.primary.background}
											css={{ display: "inline-block" }}
										>
											{tTable.headerRow.col4}
										</Typography>
									</div>
								</div>
								{tTable.rows.map((row, index) => {
									return (
										<div css={tTableRowStyles}>
											<div css={tTableCol1}>
												<Typography
													variant="div"
													weight="bold"
													size="sm"
													color={theme.colors.primary.secondaryBrand}
													css={{ width: "85%" }}
												>
													{row.col1}
												</Typography>
											</div>
											<div css={tTableCol2}>
												<Typography
													variant="div"
													weight="bold"
													size="md"
													color={theme.colors.primary.secondaryBrand}
												>
													{row.col2}
												</Typography>
											</div>
											<div css={tTableCol3}>
												<Typography
													variant="div"
													weight="bold"
													size="md"
													color={theme.colors.primary.secondaryBrand}
												>
													{row.col3}
												</Typography>
											</div>
											<div css={tTableCol4}>
												<Typography
													variant="div"
													weight="bold"
													size="md"
													color={theme.colors.primary.secondaryBrand}
												>
													{row.col4}
												</Typography>
											</div>
										</div>
									);
								})}
								<Typography
									variant="p"
									color={theme.colors.primary.lighterGray}
									size="xs"
									italic={true}
									css={{ marginTop: "25px" }}
								>
									{tTable.disclaimer}
								</Typography>
							</Column>
						</Row>
					</Grid>
					<BottomSpacer theme={theme} />
				</>
			);
		} else if (heroType === "simpleRight" || !heroType) {
			return (
				<>
					<Grid>
						<Row>
							<Column lg={6}>
								<Typography
									variant="h1"
									type="tertiary"
									css={{ marginBottom: "56px", marginTop: "35px" }}
								>
									{heroTitle}
								</Typography>
								<HeroText>
									<Typography
										variant="p"
										color={theme.colors.primary.lighterGray}
										size="md"
									>
										{heroDescription}
									</Typography>
								</HeroText>
							</Column>
							<Column lg={6}>
								{heroVideoId ? (
									<VideoWrapper
										theme={theme}
										css={css`
											width: 100%;
											@media only screen and (max-width: ${theme.breakpoints
													.lg}) {
												height: 350px;
												max-width: 443px;
												display: block;
												margin: 0 auto 30px auto;
											}
											@media only screen and (max-width: ${theme.breakpoints
													.sm}) {
												height: 280px;
											}
										`}
									>
										<Video
											videoId={heroVideoId}
											buttonOrientation="right"
											imageUrl={thumbnailUrl}
										></Video>
									</VideoWrapper>
								) : (
									<ImageWrapper theme={theme}>
										<img src={thumbnailUrl} css={imageStyles} />
									</ImageWrapper>
								)}
							</Column>
						</Row>
					</Grid>
					{heroTable ? (
						<div css={tableStyles}>
							{heroTable.map((row, index) => {
								return (
									<div css={tableRowStyles} key={index}>
										<div css={tableColumnOne}>
											<Typography
												variant="div"
												weight="bold"
												size="sm"
												color={theme.colors.primary.secondaryBrand}
											>
												{row.col1}
											</Typography>
										</div>
										<div css={tableColumnTwo}>
											{row.col2.map((line, index) => {
												return (
													<Typography
														variant="div"
														size="sm"
														color={theme.colors.primary.secondaryBrand}
														key={index}
														css={columnTwoTextStyles}
													>
														<Typography
															font="inherit"
															weight="bold"
															size="sm"
															color={
																theme.colors.primary.secondaryBrand
															}
															key={`${index}${Math.random()}`}
														>
															{line.bold}
														</Typography>{" "}
														{line.normal}
													</Typography>
												);
											})}
										</div>
										<div css={tableColumnThree}>
											{row.buttonLabel.length > 0 && (
												<Button
													type="secondary"
													size="xs"
													paddingType="relaxed"
													fullwidth={true}
													align="center"
													label={row.buttonLabel}
													onClick={() => {
														navigate(row.linkAddress);
													}}
												/>
											)}
										</div>
										{row.unlinedRow !== true && <Divider theme={theme} />}
									</div>
								);
							})}
						</div>
					) : (
						<></>
					)}
					<BottomSpacer theme={theme} />
				</>
			);
		}
	};

	return (
		<>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography variant="h1" type="tertiary">
						{heroTitle}
					</Typography>
					<div css={mobileDescriptionText}>
						<Typography variant="p" color={theme.colors.primary.lighterGray} size="lg">
							{heroDescription}
						</Typography>
					</div>
					{heroVideoId ? (
						<VideoWrapper
							theme={theme}
							css={css`
								width: 100%;
								@media only screen and (max-width: ${theme.breakpoints.lg}) {
									height: 350px;
									max-width: 443px;
									display: block;
									margin: 0 auto 30px auto;
								}
								@media only screen and (max-width: ${theme.breakpoints.sm}) {
									height: 240px;
								}
							`}
						>
							<Video
								videoId={heroVideoId}
								buttonOrientation="bottom"
								imageUrl={thumbnailUrl}
							></Video>
						</VideoWrapper>
					) : (
						<ImageWrapper theme={theme}>
							<img src={thumbnailUrl} css={imageStyles} />
						</ImageWrapper>
					)}
				</ContentSection>
				{heroTable ? (
					<MobileContainer>
						<ContentSection type="primary" vPadding={"0"}>
							<Accordion multiple={true}>
								<AccordionTab
									header={mobileHeaderText}
									circled={true}
									multiple={true}
									tabIndex={2}
								>
									{heroTable.slice(1).map((row, index) => {
										return (
											<div css={mobileRow} key={index}>
												<div css={mobileProcessTitle}>
													<Typography
														variant="p"
														weight="bold"
														size="lg"
														color={theme.colors.primary.secondaryBrand}
													>
														{row.col1}
													</Typography>
												</div>
												<div css={mobileProcessButtonContainer}>
													{row.buttonLabel.length > 0 && (
														<Button
															type="secondary"
															size="xs"
															paddingType="relaxed"
															fullwidth={true}
															align="center"
															label={row.buttonLabel}
															onClick={() => {
																navigate(row.linkAddress);
															}}
														/>
													)}
												</div>
												<div>
													{row.col2.map((line, index) => {
														return (
															<Typography
																variant="div"
																size="sm"
																type="primary"
																key={index}
															>
																<Typography
																	font="inherit"
																	weight="bold"
																	size="sm"
																	key={`${index}${Math.random()}`}
																>
																	{line.bold}
																</Typography>{" "}
																{line.normal}
															</Typography>
														);
													})}
												</div>
												{row.unlinedRow !== true && (
													<Divider theme={theme} />
												)}
											</div>
										);
									})}
								</AccordionTab>
							</Accordion>
							<MobileDivider theme={theme} />
						</ContentSection>
					</MobileContainer>
				) : (
					<></>
				)}
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					{heroLayout(
						heroTitle,
						heroDescription,
						heroVideoId,
						heroTable,
						heroType,
						tTable,
						thumbnailUrl
					)}
				</ContentSection>
			</Media>
		</>
	);
};

export default Hero;
